import React from 'react'

import Layout from '../components/Layout'
import Container from '../components/Container'

import './Sent.scss'

const Sent = ({ location }) =>
  <Layout
    location={location}
    title='Pawsome Dogs | Chicago Dog Training | Contact'
    description='Pawsome Dogs provides various services. We work with dogs of all breeds and ages. Choose a service to get started!'>
    <Container className='Sent'>
      <p>
        Thank you for contacting us! Your information has been sent. If
        you do not hear from us in 48 hours, check your spam or junk mail folder.
      </p>
    </Container>
  </Layout>

export default Sent
